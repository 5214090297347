<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">

      <!-- Forgot Password v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <img
            src="@/assets/images/logo/logo_sob.svg"
            height="70"
            width="80"
            alt=""
            srcset=""
          >
          <img
            src="@/assets/images/logo/deno.svg"
            height="40"
            width="103"
            class="mt-1"
            alt=""
            srcset=""
          >
        </b-link>

        <b-card-title class="mb-1">
          Mot de passe oublié ? 🔒
        </b-card-title>
        <b-card-text class="mb-2">
          Entrez votre email et nous vous enverrons des instructions pour réinitialiser votre mot de passe.
        </b-card-text>

        <!-- form -->
        <b-form
          class="auth-forgot-password-form mt-2"
          @submit.prevent="formSubmit"
        >
          <!-- email -->
          <b-form-group
            label="Email"
            label-for="forgot-password-email"
          >
            <b-form-input
              id="forgot-password-email"
              v-model="$v.userEmail.$model"
              :class="{ 'is-invalid': $v.userEmail.$error }"
              name="forgot-password-email"
              placeholder="toto@sobemap.com"
              autofocus
            />
            <div
              v-if="$v.userEmail.$error"
              class="invalid-feedback"
            >
              <span
                v-if="!$v.userEmail.email"
              >Veuillez utiliser un mail valide.</span>

              <span
                v-if="!$v.userEmail.required"
              >Veuillez fournir votre adresse mail.</span>
            </div>
          </b-form-group>

          <!-- submit button -->
          <b-button
            variant="primary"
            block
            type="submit"
            :disabled="process || loaded"
          >
            <b-spinner
              v-if="process || loaded"
              class="mr-1"
              small
            />
            <span v-show="!process">Envoyer</span>

          </b-button>
        </b-form>

        <b-card-text class="text-center mt-2">
          <b-link :to="{name:'login'}">
            <feather-icon icon="ChevronLeftIcon" /> Se connecter
          </b-link>
        </b-card-text>

        <div class="divider my-2">
          <div class="divider-text text-primary font-weight-bold h5">
            SOBEMAP
          </div>
        </div>

      </b-card>
    <!-- /Forgot Password v1 -->
    </div>
  </div>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions, mapGetters } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'

export default {
  components: {
  },
  data() {
    return {
      loaded: false,
      userEmail: null,
      callBackUrl: `${window.location.hostname}/reset-password`,
    }
  },
  computed: {
    ...mapGetters({
      process: 'resetProcess',
      error: 'resetError',
      success: 'resetSuccess',
    }),
  },
  watch: {
    success(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Réinitialisation',
            text: 'Un mail vous a été envoyé pour la réinitialisation.',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
        this.$router.push('login')
      }
    },
    error(val) {
      if (val) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Réinitialisation',
            text: 'Une erreur est survenue.',
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  validations: {
    userEmail: {
      email,
      required,
    },
  },
  methods: {
    ...mapActions(['initPassReset']),
    async formSubmit() {
      this.$v.$touch()
      if (this.$v.$error) return
      this.loaded = true

      await this.$http.post(`/api/oauth/check-email-exist?email=${this.userEmail}`)
        .then(res => {
          this.loaded = false
          if (res.data.valid) {
            this.initPassReset({
              email: this.userEmail,
            })
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Réinitialisation',
                text: 'Email non existant.',
                icon: 'XCircleIcon',
                variant: 'warning',
              },
            })
          }
        })
        .catch(() => {
          this.loaded = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Réinitialisation',
              text: 'Une erreur est survenue.',
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
